import '@/@fake-db/db'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

// Vue.use(require('vue-moment'))

import moment from 'moment-timezone'
import VueMoment from 'vue-moment'

Vue.use(VueMoment, {
  moment,
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAcjiwmtcAvLbb7J8X5rxDoWWmVEYJTUGA',
    libraries: 'places',
  },
  installComponents: true,
})
Vue.component('google-map', VueGoogleMaps.Map)

import VueSocketIO from 'vue-socket.io'

//const options = { path: '/my-app/' }; //Options object to pass into SocketIO

// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: 'https://app-yaqui.herokuapp.com',
//     vuex: {
//       store,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_',
//     },
//   }),
// )

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: 'https://yaqui.mecasite.com',
    // vuex: {
    //     store,
    //     actionPrefix: 'SOCKET_',
    //     mutationPrefix: 'SOCKET_'
    // },
    // options: { path: "/my-app/" } //Optional options
  }),
)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
