import appAndPages from './app-and-pages'
// import chartsAndMaps from './charts-and-maps'
// import dashboard from './dashboard'
// import formsAndTables from './forms-and-tables'
// import others from './others'
// import uiElements from './ui-elements'

// Array of sections
export default [
    // ...dashboard, 
    ...appAndPages, 
    // ...uiElements, 
    // ...formsAndTables, 
    // ...chartsAndMaps, 
    // ...others
]
