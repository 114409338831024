const apps = [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/configuration',
    name: 'apps-configuration',
    component: () => import('@/views/apps/ya_configuration/Configuration.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/rates',
    name: 'apps-rates',
    component: () => import('@/views/apps/ya_configuration/Rates.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/users',
    name: 'apps-users',
    component: () => import('@/views/apps/ya_users/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/categories',
    name: 'apps-categories',
    component: () => import('@/views/apps/ya_categories/CategoriesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/accounts',
    name: 'apps-accounts',
    component: () => import('@/views/apps/ya_accounts/AccountsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/costs',
    name: 'apps-costs',
    component: () => import('@/views/apps/ya_costs/CostsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/subcategories',
    name: 'apps-subcategories',
    component: () => import('@/views/apps/ya_subcategories/SubCategoriesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/products',
    name: 'apps-products',
    component: () => import('@/views/apps/ya_products/ProductsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/orders',
    name: 'apps-orders',
    component: () => import('@/views/apps/ya_orders/OrdersList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/maporder/:id',
    name: 'apps-maporder',
    component: () => import('@/views/apps/ya_orders/MapOrder.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/neworder/',
    name: 'apps-neworder',
    component: () => import('@/views/apps/ya_orders/NewOrder.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/neworderv2/',
    name: 'apps-neworderv2',
    component: () => import('@/views/apps/ya_orders/NewOrderV2.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/customers',
    name: 'apps-customers',
    component: () => import('@/views/apps/ya_customers/CustomersList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/banners',
    name: 'apps-banners',
    component: () => import('@/views/apps/ya_banners/BannersList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/discountcodes',
    name: 'apps-discountCodes',
    component: () => import('@/views/apps/ya_discount_codes/DiscountCodesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/reports',
    name: 'apps-reports',
    component: () => import('@/views/apps/ya_reports/Reports.vue'),
    meta: {
      layout: 'content',
    },
  },
  //
  //* ——— Invoice ——————————————————
  //

  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/add/',
    name: 'apps-invoice-add',
    component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/edit/:id',
    name: 'apps-invoice-edit',
    component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/apps/user/list',
    name: 'apps-user-list',
    component: () => import('@/views/apps/user/user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/user/view/:id',
    name: 'apps-user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Chat ——————————————————
  //

  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Email ——————————————————
  //

  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default apps
