<template>
  <component class="bg" :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useLayout } from '@core/layouts/composable/useLayout'
import { useRouter } from '@core/utils'

// Layouts
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import Push from 'push.js'

export default {
  sockets: {
    connect: function () {
      console.log('conectado a cockets. en app.vue')
    },
    orden_sin_candidatos: async function (data) {
      console.log('orden sin candidatos', data)
      alert('orden sin driver, cliente esperando asignación!!!!')
      Push.Permission.get()
      Push.create('Orden sin driver asignado!', {
        //Titulo de la notificación
        body: data.descripcion, //Texto del cuerpo de la notificación
        icon: 'https://admin.appyaqui.com/img/yaqui.7c8b493b.jpg', //Icono de la notificación
        timeout: 6000, //Tiempo de duración de la notificación
        // onClick: function () {//Función que se cumple al realizar clic cobre la notificación
        // 	window.location = "https://01luisrene.com"; //Redirige a la siguiente web
        // 	this.close(); //Cierra la notificación
        // }
      })
      // if (Number(usuario_idt) == Number(data.para)) {
      //   let listChat = JSON.parse(localStorage.getItem('listChat2')) || []
      //   console.log('listchat2', listChat)
      //   let rres = listChat.find(x => x == data.id)
      //   console.log('res bus', rres)
      //   if (listChat.indexOf(data.id) != -1) {
      //     console.log('ya me habían avisado...')
      //   } else {
      //     console.log('ok', data.id)
      //     listChat.push(data.id)
      //     localStorage.setItem('listChat2', JSON.stringify(listChat))
      //     this.newMessageFromClient(data)
      //     this.itemsUsers = await this.obtenerUsuarios()
      //   }
      // }
    },
  },
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },
  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
<style></style>