import { mdiViewModule } from '@mdi/js'

export default [
  {
    subheader: 'MODULOS',
  },
  {
    title: 'Usuarios',
    icon: mdiViewModule,
    to: 'apps-users',
    roles: '1,2',
  },
  {
    title: 'Clientes',
    icon: mdiViewModule,
    to: 'apps-customers',
    roles: '1,2,3',
  },
  {
    title: 'Cod. descuento',
    icon: mdiViewModule,
    to: 'apps-discountCodes',
    roles: '1,2,4,',
  },
  {
    title: 'Banners',
    icon: mdiViewModule,
    to: 'apps-banners',
    roles: '1,2,4,5',
  },
  {
    title: 'Categorías',
    icon: mdiViewModule,
    to: 'apps-categories',
    roles: '1,2',
  },
  {
    title: 'SubCategorías',
    icon: mdiViewModule,
    to: 'apps-subcategories',
    roles: '1,2',
  },
  {
    title: 'Productos',
    icon: mdiViewModule,
    to: 'apps-products',
    roles: '1,2',
  },
  {
    title: 'Ordenes',
    icon: mdiViewModule,
    to: 'apps-orders',
    roles: '1,2,3, 4',
  },
  {
    title: 'Cuentas Banco',
    icon: mdiViewModule,
    to: 'apps-accounts',
    roles: '1,3',
  },
  {
    title: 'Gastos',
    icon: mdiViewModule,
    to: 'apps-costs',
    roles: '1,2, 3',
  },
  {
    title: 'Reportes',
    icon: mdiViewModule,
    to: 'apps-reports',
    roles: '1,2,3',
  },
  {
    title: 'Configuración',
    icon: mdiViewModule,
    to: 'apps-configuration',
    roles: '1',
  },
  {
    title: 'Tarifas',
    icon: mdiViewModule,
    to: 'apps-rates',
    roles: '1,2',
  },
  // {
  //   title: 'Soporte',
  //   icon: mdiViewList,
  //   to: 'https://chat.appyaqui.com/#/chat/1',
  // },
  // {
  //   title: 'Productos',
  //   icon: mdiCalendarOutline,
  //   to: 'apps-products',
  // },
  // {
  //   title: 'Categorías',
  //   icon: mdiCalendarOutline,
  //   to: 'apps-categories',
  // },
  // {
  //   title: 'Subcategorías',
  //   icon: mdiCalendarOutline,
  //   to: 'apps-subcategories',
  // },
  // {
  //   title: 'Calendar',
  //   icon: mdiCalendarOutline,
  //   to: 'apps-calendar',
  // },
  // {
  //   title: 'Chat',
  //   icon: mdiMessageTextOutline,
  //   to: 'apps-chat',
  // },
  // {
  //   title: 'Email',
  //   icon: mdiEmailOutline,
  //   to: 'apps-email',
  // },

  // {
  //   title: 'Invoice',
  //   icon: mdiFileDocumentOutline,
  //   children: [
  //     {
  //       title: 'List',
  //       to: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Preview',
  //       to: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Edit',
  //       to: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Add',
  //       to: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },
  // {
  //   title: 'User',
  //   icon: mdiAccountOutline,
  //   children: [
  //     {
  //       title: 'User List',
  //       to: 'apps-user-list',
  //     },
  //     {
  //       title: 'User View',
  //       to: { name: 'apps-user-view', params: { id: 21 } },
  //     },
  //   ],
  // },
  // {
  //   title: 'Pages',
  //   icon: mdiFileOutline,
  //   children: [
  //     {
  //       title: 'Authentication',
  //       children: [
  //         {
  //           title: 'Login V1',
  //           to: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login V2',
  //           to: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register V1',
  //           to: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register V2',
  //           to: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password V1',
  //           to: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password V2',
  //           to: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password V1',
  //           to: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password V2',
  //           to: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       children: [
  //         {
  //           title: 'Under Maintenance',
  //           to: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           to: 'misc-error',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Coming Soon',
  //           to: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           to: 'misc-not-authorized',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       to: 'page-knowledge-base',
  //     },
  //     {
  //       title: 'Account Settings',
  //       to: 'page-account-settings',
  //     },
  //     {
  //       title: 'Pricing',
  //       to: 'page-pricing',
  //     },
  //     {
  //       title: 'FAQ',
  //       to: 'page-faq',
  //     },
  //   ],
  // },
]
